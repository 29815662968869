.App {
  text-align: center;
  /* Default background color */
  background-color: #f00404;
  /* Default text color */
  color: #000000;
}

/* Base dark mode styles */
.App.dark {
  /* Background color */
  background-color: #111827;
  /* Text color */
  color: #e5e7eb;
}

.dark-mode-toggle {
  margin: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  background-color: #007bff;
  color: #ffffff;
  cursor: pointer;
  font-size: 1rem;
}
