.button-among {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0 10px;
    color: #111827;
    text-shadow: 1px 1px rgb(116, 116, 116);
    text-transform: uppercase;
    cursor: pointer;
    border: solid 2px black;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 17px;
    background-color: white;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease;
  }
  
  button:active {
    transform: scale(0.9);
    transition: all 100ms ease;
  }
  
  button svg {
    transition: all 0.5s ease;
    z-index: 2;
  }
  
  .play {
    transition: all 0.5s ease;
    transition-delay: 300ms;
  }
  
  button:hover svg {
    transform: scale(3) translate(50%);
  }
  
  .now {
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    transition: all 0.5s ease;
    z-index: 2;
  }
  
  button:hover .now {
    transform: translateX(10px);
    transition-delay: 300ms;
  }
  
  button:hover .play {
    transform: translateX(200%);
    transition-delay: 300ms;
  }
  